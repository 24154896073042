<template>
  <div class="hello">
    <img src="../assets/back.png" alt="" class="img" />
    <div
      style="
        position: relative;
        width: 100%;
        height: 100%;
        padding-bottom: 280px;
      "
    >
      <div class="menu">
        <div
          style="
            margin: 0 auto;
            width: 75%;
            box-sizing: border-box;
            margin-top: 13%;
          "
        >
          <h4
            style="
              color: #ffffff;
              margin-bottom: 40px;
              font-weight: 500;
              font-size: 24px;
              text-align: center;
              letter-spacing: 3px;
            "
          >
            立体云防控
          </h4>
          <Form ref="formInline" :model="formInline" :rules="ruleInline">
            <div style="opacity: 0.5">
              <FormItem prop="user">
                <Input
                  type="text"
                  v-model="formInline.user"
                  placeholder="请输入账号"
                  class="ivu-input-wrapper"
                >
                  <Icon type="ios-person-outline" slot="prepend"></Icon>
                </Input>
              </FormItem>
            </div>
            <div style="opacity: 0.5; background: transparent">
              <FormItem prop="password">
                <Input
                  type="password"
                  v-model="formInline.password"
                  placeholder="请输入密码"
                  class="ivu-input-wrapper"
                >
                  <Icon type="ios-lock-outline" slot="prepend"></Icon>
                </Input>
              </FormItem>
            </div>
            <div style="color: #ffffff">
              <!-- <label class="ivu-checkbox-wrapper ivu-checkbox-wrapper-checked">
                <span class="ivu-checkbox ivu-checkbox-checked"></span>
                <span class="ivu-checkbox-inner"
                  ><input type="checkbox" class="ivu-checkbox-input"
                /></span>
                记住密码
              </label> -->
              <Checkbox v-model="single" size="small">记住密码</Checkbox>
            </div>
            <div style="height: 40px"></div>
            <FormItem>
              <Button type="primary" long>登陆</Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
    <div style="position: fixed; bottom: 0; left: 37%; color: #ffffff">
      工业和信息化部备案管理系统网站 <a href="https://beian.miit.gov.cn">蜀ICP备2021017284号-2</a> 技术支持：成都华宇一城科技有限公司
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        password: "",
      },
      ruleInline: {
        user: [
          {
            required: true,
            message: "Please fill in the user name",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password.",
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: "The password length cannot be less than 6 bits",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Message.success("Success!");
        } else {
          this.$Message.error("Fail!");
        }
      });
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.menu {
  /* background: url("../assets/下载.png")480px 720px;
  background-color: aqua; */
  width: 400px;
  height: 500px;
  background-color: rgba(246, 243, 243, 0.109);
  position: absolute;
  left: 64%;
  top: 50%;
}

</style>
